import React from 'react';
import { Route, RouteProps } from "react-router-dom";
import { Header } from '../../components/index';


interface Props {
  exact?: boolean;
  component?: any;
  path: string;
  requestLogin?: () => void;
}

const MainLayout: React.FC<RouteProps & Props> = ({ component: Component, ...rest }) => {
  return (
    <div className="main-layout">
      <Header />
      <div className="content-wrapper">
        <Route {...rest} render={matchProps => (
          <Component {...matchProps} />
        )} />
      </div>
    </div>
  )
};

export default MainLayout;
