import React from 'react';
import { useTable } from 'react-table';


interface Props {
  data: any;
  columns: any;
  onClickRow: (row: any) => void;
}

const Table: React.SFC<Props> = (props) => {
  const { data, columns, onClickRow } = props;


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  return (
    <article className="react-table__container">
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>

          {rows.length === 0 ? (
            <tr role="row" className="empty__box">
              <td colSpan={columns.length}>
                데이터가 없습니다.
              </td>
            </tr>
          ) : (

              rows.map(row => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()} onClick={() => onClickRow(row)}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })

            )
          }
        </tbody>


      </table>
    </article>
  )
}

export default Table;


