import React from 'react';
import { LoginForm } from '../../components';

const Login = () => {

  return (
    <article className="login-page">
      <section className="logo__container">
        <div className="ico-logo">
        </div>
        <h1>
          Partner
        </h1>
      </section>
      <section className="form__container">
        <LoginForm />
      </section>
    </article>
  );
}

export default Login;