import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context';
import DatePicker from "react-datepicker";
import { CustomChart, Table } from '../../components';
import API from '../../api';


interface datesType {
  startDate: Date,
  endDate: Date,
}

interface TableDataType {
  area: string;
  installCount: number;
  installDate: Date;
  isOn: boolean;
  license: string;
  pharmName: string;
  receiptDate: string;
  status: string;
  storeId: string;
}

interface StatusObjectType {
  [R: string]: string;
  S: string;
  H: string;
  F: string;
  C: string;
}

export interface dataObjectType {
  [holdList: string]: Array<any>;
  receiptList: Array<any>;
  scheduleList: Array<any>;
}

const STATUS_OBJECT: StatusObjectType = {
  'R': '접수',
  'S': '예정',
  'H': '보류',
  'F': '완료',
  'C': '취소'
};

const IndexToDataKey = (index: any) => {
  switch (index) {
    case 0: {
      return 'receiptList'
    }
    case 1: {
      return 'scheduleList'
    }
    case 2: {
      return 'holdList'
    }
    default: {
      return 'receiptList'
    }
  }
}

const IndexToTitle = (index: any) => {
  switch (index) {
    case 0: {
      return '설치접수'
    }
    case 1: {
      return '설치예정'
    }
    case 2: {
      return '설치보류'
    }
    default: {
      return ''
    }
  }
}

const DateStringToYYYYMMDD = (dateSting: string) => {
  return dateSting.slice(0, 10)
}

const DashBoard = () => {
  let history = useHistory();
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 6);
  const initialStartDate = new Date(2019, 9, 1);

  const initialDates: datesType = {
    startDate: initialStartDate,
    endDate: new Date(),
  }

  const initialSearchResult: dataObjectType = {
    holdList: [],
    scheduleList: [],
    receiptList: [],
  }

  const initialTableData: Array<TableDataType> = [];

  const { user } = useContext(UserContext);
  const [searchDates, updateSearchDates] = useState(initialDates);
  const [searchResult, updateSearchResult] = useState(initialSearchResult);
  const [tableData, updateTableData] = useState(initialTableData);
  const [selectedIndex, updateSelectedIndex] = useState(0);

  useEffect(() => {
    getNewSearchResult();
  }, [])

  const onChangePicker = (dataValue: Date, key: string) => {
    console.log('onChangePicker', dataValue)
    updateSearchDates({
      ...searchDates,
      [key]: dataValue,
    })
  }

  const requestSearch = () => {
    console.log('requestSearch', searchDates)
    getNewSearchResult();

  }

  const getNewSearchResult = () => {
    const startDateString = DateStringToYYYYMMDD(searchDates.startDate.toISOString());
    const endDateString = DateStringToYYYYMMDD(searchDates.endDate.toISOString());

    console.log('dashboad did mount', endDateString)

    API.Statistics.getDashBoard(startDateString, endDateString).then((res: any) => {
      if (res.data.code === 0 && res.data.data) {
        const selectedList = res.data.data[IndexToDataKey(selectedIndex)];
        updateTableData(selectedList);
        updateSearchResult(res.data.data);
      }
    })
  }

  useEffect(() => {
    const selectedList = searchResult[IndexToDataKey(selectedIndex)];
    updateTableData(selectedList);
  }, [selectedIndex])

  const onClickRow = (row: any) => {
    console.log('onClickRow', row)
    const selectedIndex = row.index;
    const targetStoreId = tableData[selectedIndex].storeId;
    history.push(`/store/detail/${targetStoreId}`);
  }

  const TableColumns = [
    {
      Header: '약국명',
      accessor: 'pharmName',
    },
    {
      Header: '스토어ID',
      accessor: 'storeId',
    },
    {
      Header: '지역',
      accessor: 'area',
    },
    {
      Header: '상태',
      accessor: 'status',
      Cell: (props: any) => {
        return (
          <span className={'status-text ' + props.cell.value}>{STATUS_OBJECT[props.cell.value]}</span>
        );
      },
    },
    {
      Header: '접수일자',
      accessor: 'receiptDate',
      Cell: (props: any) => {
        return (
          <span>{DateStringToYYYYMMDD(props.cell.value)}</span>
        );
      },
    },
  ];

  return (
    <article className="dashBoard-page">
      <section className="page-header two-title">
        <h1>기간별 설치현황</h1>
        <h1>{IndexToTitle(selectedIndex)} 리스트({tableData.length})</h1>
      </section>
      <section className="chart__container">
        <section className="chart__box">
          <header>
            <h1>
              {IndexToTitle(selectedIndex)}({tableData.length})
            </h1>
            <div className="date__box">
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={searchDates.startDate}
                onChange={(value) => onChangePicker(value || new Date(), 'startDate')}
              />
              <span>~</span>
              <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={searchDates.endDate}
                onChange={(value) => onChangePicker(value || new Date(), 'endDate')}
              />
              <button className="search__btn" onClick={requestSearch}>조회</button>
            </div>
          </header>
          <CustomChart
            data={searchResult}
            selectedIndex={selectedIndex}
            onClick={(index: number) => updateSelectedIndex(index)}
          />
        </section>
        <section className="table__box">
          <Table
            onClickRow={onClickRow}
            data={tableData}
            columns={TableColumns} />
        </section>
      </section>
    </article>
  );
}

export default DashBoard;