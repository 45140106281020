import React, { useState, useEffect, useContext } from "react";
import { UserContext, AlertContext } from "../../context";
import { useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import { CustomDropdown } from "../../components";
import _ from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import API from "../../api";
import Modal from "react-modal";
import DaumPostcode from "react-daum-postcode";

// for kakao map service
declare global {
  interface Window {
    kakao: any;
  }
}

interface basicInfoType {
  [pharmName: string]: any;
  licenseNo: string;
  address: string;
  addressDetail: string;
  tel: string;
  masterName: string;
  phone: string;
  lat: string;
  lng: string;
  taxNo: string;
  receiptDate: string;
  program: string;
  serviceType: string;
}

interface installInfoType {
  [installDate: string]: any;
  status: string;
  storeId: string;
  tabletList: Array<any>;
  comments: string;
}

const InstallStatusDropdownOption = [
  "설치접수",
  "설치예정",
  "설치보류",
  "설치완료",
  "설치취소",
];

const ServiceTypeDropdownOption = ["Lite", "Plus"];

const ProgramDropdownOption = [
  "PIT3000",
  "UPharm",
  "NSPharm",
  "CNPharm",
  "BizPharm",
];

const serviceTypeToString = (text: string) => {
  switch (text) {
    case "L": {
      return "Lite";
    }
    case "P": {
      return "Plus";
    }
    default: {
      return "";
    }
  }
};

const statusValueToString = (text: string) => {
  switch (text) {
    case "R": {
      return "설치접수";
    }
    case "S": {
      return "설치예정";
    }
    case "H": {
      return "설치보류";
    }
    case "F": {
      return "설치완료";
    }
    case "C": {
      return "설치취소";
    }
    default: {
      return "";
    }
  }
};

const ParseDate = (str: string) => {
  var y = str.substr(0, 4);
  var m = str.substr(5, 2);
  var d = str.substr(8, 2);
  return new Date(Number(y), Number(m) - 1, Number(d));
};

const basicInfoArray = [
  {
    type: "input",
    value: "receiptDate",
    label: "접수일자",
  },
  {
    type: "dropdown",
    value: "serviceType",
    label: "서비스타입",
  },
  {
    type: "dropdown",
    value: "program",
    label: "약국 프로그램",
  },
  {
    type: "input",
    value: "pharmName",
    label: "약국명",
  },
  {
    type: "input",
    value: "masterName",
    label: "대표 약사이름",
  },
  {
    type: "input",
    value: "licenseNo",
    label: "면허번호",
    placeholder: "5자리",
  },
  {
    type: "input",
    value: "tel",
    label: "전화번호",
    placeholder: "-를 제외한 숫자만 입력, 11자리",
  },
  {
    type: "input",
    value: "phone",
    label: "핸드폰 번호",
    placeholder: "-를 제외한 숫자만 입력, 11자리",
  },
  {
    type: "input",
    value: "address",
    label: "주소",
  },
  {
    type: "input",
    value: "addressDetail",
    label: "상세 주소",
  },
  {
    type: "input",
    value: "taxNo",
    label: "사업자 번호",
  },
];

const installInfoArray = [
  {
    type: "input",
    value: "storeId",
    label: "스토어ID",
  },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "460px",
    height: "400px",
  },
};

export const StoreDetail = (props: any) => {
  let history = useHistory();
  const initialBasicInfo: basicInfoType = {
    pharmName: "",
    licenseNo: "",
    address: "",
    addressDetail: "",
    tel: "",
    masterName: "",
    phone: "",
    lat: "",
    lng: "",
    area: "",
    taxNo: "",
    comments: "",
    receiptDate: "",
    program: "",
    serviceType: "",
  };

  const initialInstallInfo: installInfoType = {
    installDate: new Date(),
    status: InstallStatusDropdownOption[0],
    storeId: "",
    tabletList: [],
    comments: "",
  };

  const InitialAddressInfo = {
    lat: "",
    lng: "",
    sido: "",
    sidogu: "",
    sidoguCode: "",
  };

  const { user } = useContext(UserContext);
  const { requestAlert } = useContext(AlertContext);
  const [basicInfo, updateBasicInfo] = useState(initialBasicInfo);
  const [installInfo, updateInstallInfo] = useState(initialInstallInfo);
  const [targetStoreId, updateTargetStoreId] = useState("");
  const [modalOpen, updateModalOpen] = useState(false);
  const [addressInfo, updateAddressInfo] = useState(InitialAddressInfo);

  useEffect(() => {
    Modal.setAppElement("body");

    console.log("Register Page useEffect [update Target StoreId]", props);
    if (props.match.params && props.match.params.storeId) {
      //storeID CASE
      const updatedStoreId = props.match.params.storeId;
      updateTargetStoreId(props.match.params.storeId);
      API.Pharm.getDetail(updatedStoreId).then((res) => {
        console.log("getDetail res", res.data.data);
        if (res && res.data && res.data.data) {
          const data = res.data.data;
          const basicInfoObject: basicInfoType = {
            pharmName: data.pharmName,
            licenseNo: data.licenseNo,
            address: data.address,
            addressDetail: data.addressDetail,
            tel: data.tel,
            masterName: data.masterName,
            phone: data.phone,
            lat: data.lat,
            lng: data.lng,
            taxNo: data.taxNo || "",
            receiptDate: data.receiptDate.substring(0, 10),
            program: data.program,
            serviceType: serviceTypeToString(data.serviceType),
          };
          updateBasicInfo(basicInfoObject);

          const installInfoObejct: installInfoType = {
            installDate: data.installDate ? ParseDate(data.installDate) : null,
            status: statusValueToString(data.status),
            storeId: updatedStoreId,
            tabletList: data.tabletList,
            comments: data.comments || "",
          };
          updateInstallInfo(installInfoObejct);

          const addressObejct: any = {
            lat: data.lat,
            lng: data.lng,
            sido: data.sido,
            sidogu: data.sidogu,
            sidoguCode: data.sidoguCode,
          };
          updateAddressInfo(addressObejct);
        }
      });
    }
  }, []);

  const onSubmit = () => {
    console.log("basicInfo", basicInfo);
    console.log("installInfo", installInfo);
    console.log("addressInfo", addressInfo);
    let modifyObj = _.merge(basicInfo, installInfo, addressInfo);
    console.log("modifyObj!", modifyObj);
    API.Pharm.modify(modifyObj).then(
      (res) => {
        console.log("modify res", res);
        if (res.data && res.data.code === 0) {
          requestAlert("confirm", "성공적으로 수정되었습니다.");
        } else {
          requestAlert("error", res.data.message);
        }
      },
      (err) => {
        requestAlert("error", "입력정보를 확인해주세요.");
      }
    );
  };

  const onCancle = () => {
    history.goBack();
  };

  const onDelete = () => {
    const result = window.confirm(
      "운영 중에는 삭제가 불가능합니다. 삭제 하시겠습니까?"
    );
    if (result) {
      API.Pharm.remove(targetStoreId).then(
        (res) => {
          console.log("remove res", res);
          if (res.data && res.data.code === 0) {
            history.push("/store/management");
            requestAlert("confirm", "성공적으로 삭제되었습니다.");
          } else {
            requestAlert("error", "오류가 발생했습니다.");
          }
        },
        (err) => {
          requestAlert("error", "오류가 발생했습니다.");
        }
      );
    }
  };

  const onChangeInput = (e: any, type: string) => {
    let value = e.target.value;
    const key = e.target.name;

    e.persist();

    if (type === "basic") {
      updateBasicInfo({
        ...basicInfo,
        [key]: value,
      });
    } else {
      updateInstallInfo({
        ...installInfo,
        [key]: value,
      });
    }
  };

  const handleInstallDatePicker = (dateValue: Date) => {
    console.log("dateValue", dateValue);
    updateInstallInfo({
      ...installInfo,
      installDate: dateValue,
    });
  };

  const onChangeDropdown = (dropdownValue: any, key: string) => {
    if (key === "serviceType" || key === "program") {
      console.log("dropdownValue", dropdownValue);
      updateBasicInfo({
        ...basicInfo,
        [key]: dropdownValue.value,
      });
    } else {
      updateInstallInfo({
        ...installInfo,
        [key]: dropdownValue.value,
      });
    }
  };

  const toggleModal = (value: boolean) => {
    updateModalOpen(value);
  };

  const handleComplete = (data: any) => {
    console.log("data", data);
    let selectedAddress = "";
    if (data.userSelectedType == "R") {
      selectedAddress = data.roadAddress;
    } else {
      selectedAddress = data.jibunAddress;
    }
    console.log("selectedAddress", selectedAddress);
    updateBasicInfo({
      ...basicInfo,
      address: selectedAddress,
    });

    // add amp
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://dapi.kakao.com/v2/maps/sdk.js?appkey=bbba07d476d301ed7d0a507b46445993&autoload=false&libraries=services";
    document.head.appendChild(script);
    script.onload = () => {
      window.kakao.maps.load(() => {
        var geocoder = new window.kakao.maps.services.Geocoder();
        // 주소로 좌표를 검색합니다
        geocoder.addressSearch(selectedAddress, function (
          result: any,
          status: any
        ) {
          console.log("status", status);
          if (status == "OK" && result[0]) {
            console.log("result[0]", result[0]);
            let x = result[0].x; //경도
            let y = result[0].y; // 위도
            let sido = data.sido;
            let sigungu = data.sigungu;
            let sigunguCode = data.sigunguCode; //시군구 코드

            const newAddressInfo = {
              lat: y, //위도
              lng: x, //경도
              sido: sido,
              sidogu: sigungu,
              sidoguCode: sigunguCode,
            };
            updateAddressInfo(newAddressInfo);
            toggleModal(false);
          }
        });
      });
    };
  };

  return (
    <article className="store-register-page">
      <section className="page-header">
        <h1>{targetStoreId ? "약국수정" : "약국등록"}</h1>
      </section>
      <article className="basic-info">
        <section className="form__box">
          <header>기본 정보</header>
          <ul className="basic-form__list">
            {basicInfoArray.map((item, index) => {
              if (item.type === "dropdown") {
                if (item.value === "serviceType") {
                  return (
                    <li>
                      <label>서비스 타입</label>
                      <CustomDropdown
                        options={ServiceTypeDropdownOption}
                        value={basicInfo[item.value]}
                        onChange={(value) =>
                          onChangeDropdown(value, "serviceType")
                        }
                      />
                    </li>
                  );
                }
                if (item.value === "program") {
                  console.log("item", item);
                  return (
                    <li>
                      <label>프로그램</label>
                      <CustomDropdown
                        options={ProgramDropdownOption}
                        value={basicInfo[item.value]}
                        onChange={(value) => onChangeDropdown(value, "program")}
                      />
                    </li>
                  );
                }
              }
              return (
                <li key={"basic_info_list_key_" + item.label}>
                  <label>{item.label}</label>
                  <input
                    disabled={
                      item.value === "receiptDate" ||
                      item.value === "licenseNo" ||
                      item.value === "address"
                        ? true
                        : false
                    }
                    name={item.value}
                    placeholder={item.placeholder}
                    value={basicInfo[item.value]}
                    onChange={(e) => onChangeInput(e, "basic")}
                  />
                  {item.value === "address" ? (
                    <button
                      className="find-address"
                      onClick={() => toggleModal(true)}
                    >
                      주소 찾기
                    </button>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </section>
        {targetStoreId && (
          <section className="form__box install">
            <header>설치 정보</header>
            <ul className="basic-form__list">
              <li>
                <label>설치 일자</label>
                <DatePicker
                  placeholderText="일자를 선택하세요."
                  dateFormat="yyyy-MM-dd"
                  selected={installInfo.installDate}
                  onChange={handleInstallDatePicker}
                />
              </li>
              {installInfoArray.map((item, index) => {
                return (
                  <li key={"install_info_list_key_" + item.label}>
                    <label>{item.label}</label>
                    <input
                      disabled={item.label === "스토어ID" ? true : false}
                      name={item.value}
                      value={installInfo[item.value]}
                      onChange={(e) => onChangeInput(e, "install")}
                    />
                  </li>
                );
              })}
              <li>
                <label>설치 상태</label>
                <CustomDropdown
                  options={InstallStatusDropdownOption}
                  value={installInfo.status}
                  onChange={(value) => onChangeDropdown(value, "status")}
                />
              </li>
              <li className="textarea">
                <label>메모</label>
                <textarea
                  name="comments"
                  value={installInfo.comments}
                  placeholder={"메모를 입력해주세요."}
                  onChange={(e) => onChangeInput(e, "install")}
                />
              </li>
              <div className="option__box">
                <header>
                  <h4>태블릿 목록</h4>
                </header>
                {installInfo.tabletList.map((item, index) => {
                  return (
                    <li key={"tablet_list_key_" + index}>
                      <div className="status__box">
                        <div
                          className={item.isOn ? "device-on" : "device-off"}
                        ></div>
                        <div
                          className={
                            item.isConnected ? "connected-on" : "connected-off"
                          }
                        ></div>
                      </div>
                      <div>
                        <label>태블릿 S/N</label>
                        <input
                          disabled={true}
                          name={"serialNo"}
                          value={item.serialNo}
                        />
                      </div>
                      <div>
                        <label>태블릿 IP</label>
                        <input
                          disabled={true}
                          name={"tabletIP"}
                          value={item.tabletIP}
                        />
                      </div>
                    </li>
                  );
                })}
              </div>
            </ul>
          </section>
        )}
      </article>

      <section className="button__box">
        <button className="cancel" onClick={onCancle}>
          취소
        </button>
        {targetStoreId && (
          <button className="delete" onClick={onDelete}>
            삭제
          </button>
        )}
        <button onClick={onSubmit}>수정</button>
      </section>
      <Modal
        isOpen={modalOpen}
        style={customStyles}
        onRequestClose={() => toggleModal(false)}
      >
        <DaumPostcode onComplete={handleComplete} />
      </Modal>
    </article>
  );
};
