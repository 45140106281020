import React, { useContext, useEffect, useRef, useState } from 'react';
import { UserContext } from '../../context/user';
import { Link, useHistory } from "react-router-dom";


const Header = () => {
  let history = useHistory();
  const { user, requestLogout } = useContext(UserContext);
  const [isExpand, updateIsExpand] = useState(false);

  const NavList = [
    {
      key: 0,
      text: '대쉬보드',
      path: '/',
      className: "dashboard",
    },
    {
      key: 1,
      text: '약국관리',
      path: '/store/management',
      className: "store",

    },
  ]

  useEffect(() => {
    console.log('header useEffect', user)
    const isToken = localStorage.getItem('token');
    console.log('isToken', isToken)
    if (!isToken) {
      //log out handelr
      history.push('/login')
    }
  }, [user]);

  useEffect(() => {
    console.log('location change')
    updateIsExpand(false)

  }, [window.location.href]);

  const reuqestMain = () => {
    history.push('/');
  }

  const expandHeader = () => {
    updateIsExpand(!isExpand)
  }

  const currentPathName = history.location.pathname;
  return (
    <article className={isExpand ? "header-container expand" : "header-container"}>
      <section className={isExpand ? "header__wrapper expand" : "header__wrapper"}>
        <section className="user-info">
          <div className="logo__box" onClick={reuqestMain}>
            <div className="ico-logo" />
            <h1>Partner</h1>
          </div>

          <section className="user__box">
            <span>
              {user.name}
            </span>
            <button className="logout__btn" onClick={requestLogout}>
              로그아웃
          </button>
          </section>
        </section>

        <ul className="nav-list">
          {NavList.map(item => {
            return (
              <li key={`header_nav_list_${item.key}`} className={currentPathName === item.path ? 'active' : ''}>
                <Link to={item.path}>
                  <div className={item.className}>
                  </div>
                  <label>
                    {item.text}
                  </label>
                </Link>
              </li>
            )
          })}
        </ul>
        <section className={isExpand ? "expand__box open" : "expand__box"}>
          <button onClick={expandHeader}>
            +
        </button>
        </section>
        <section className="footer-container">
          <span>
            고객센터 : 1600-3122
        </span>
          <span>
            © Healthport Co.Ltd. All Right Reserved.
        </span>
        </section>
      </section>
      {isExpand &&
        <section className="dim__wrapper">

        </section>
      }

    </article>
  )
}

export default Header;
