import React from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

interface Props {
  options: Array<string>;
  value: string;
  onChange: (value: any) => void;
  className?: string;
}

const CustomDropdown: React.SFC<Props> = (props) => {
  const { options, value, onChange, className } = props;

  return (
    <article className={`custom-dropdown ${className}`}>
      <Dropdown options={options} value={value} onChange={onChange} />
    </article>
  )
}

export default CustomDropdown;
