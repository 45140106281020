import User from "./user";
import Pharm from './pharm';
import Statistics from './statistics';

const API = {
  User: User,
  Pharm: Pharm,
  Statistics
};

export default API;
