import React from 'react';
import { Route, RouteProps } from "react-router-dom";

interface Props {
  exact?: boolean;
  component?: any;
  path: string;
}

const LoginLayout: React.FC<RouteProps & Props> = ({ component: Component, ...rest }) => {
  return (
    <div className="login-layout">
      <div className="content-wrapper">
        <Route {...rest} render={matchProps => (
          <Component {...matchProps} />
        )} />
      </div>
    </div>
  )
};

export default LoginLayout;
