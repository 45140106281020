import AxiosInstance from "../axiosInstance";
import {
  ObjectToParams,
  ObjectToQueryString,
  ArrayToStringComma,
  statusStringToValue,
  arrayTOStringCommaByKey,
  dateFormatToStringType,
} from "../helper";
import { pageType } from "../../components/Paging";

const getDashBoard = (startDate: string, endDate: string) => {
  const queryObject = {
    startDate: startDate,
    endDate: endDate,
  };
  const qeuryString = ObjectToQueryString(queryObject);
  return AxiosInstance.get("dashboard?" + qeuryString);
};

const Statistics = {
  getDashBoard: getDashBoard,
};

export default Statistics;
