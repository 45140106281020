import React from "react";

export interface UserContextType {
  user: UserType;
  requestLogin: (id: string, pw: string, isRetainAuth: boolean) => void;
  requestLogout: () => void;
}

export interface UserType {
  name: string;
  tel: string;
  uid: number;
}

export const user: UserType = {
  name: "",
  tel: "",
  uid: 0,
};

export const UserContext = React.createContext<UserContextType>({
  user: user,
  requestLogin: (id: string, pw: string, isRetainAuth: boolean) => {},
  requestLogout: () => {},
});
