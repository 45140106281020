import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { MainLayout, LoginLayout } from './layout';
import { Login, StoreManagement, DashBoard, StoreDetail } from './pages';
import { UserContext, UserType, AlertContext, AlertType } from './context';
import { Alert } from './components';

import API from './api';

//removing console
if (process.env.NODE_ENV !== 'development' && process.env.REACT_APP_HOST_ENV !== 'development') {
  console.log = () => { }
}

const App = () => {
  const requestLogin = (id: string, pw: string, isRetainAuth: boolean) => {
    API.User.requestLogin(id, pw, isRetainAuth).then(res => {
      console.log('res.data', res.data)

      if (res && res.data && res.data.token) {
        const userObj = {
          token: res.data.token
        }
        localStorage.setItem('token', JSON.stringify(userObj));
        requestUserInfo();
      } else {
        requestAlert('warning', '로그인 정보를 확인해주세요.')
      }
    }, err => {
      requestAlert('error', '로그인 정보를 확인해주세요.')
    })
  }

  const emptyUser: UserType = {
    name: '',
    tel: '',
    uid: 0
  }

  const initialAlert: AlertType = {
    type: "init",
    message: "init message",
  }

  const initialUser: UserType = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || "") : emptyUser;
  const [user, updateUser] = useState<UserType>(initialUser);
  const [alertData, updateAlert] = useState<AlertType>(initialAlert);

  const requestLogout = () => {
    localStorage.setItem('token', '');
    updateUser(emptyUser);
  }

  const requestAlert = (type: string, message: string) => {
    updateAlert({ type: type, message: message })
  }

  const requestUserInfo = () => {
    API.User.getUserInfo().then(res => {
      console.log('requestUserInfo res.data', res.data)
      if (res && res.data && res.data.data) {
        const userObj = {
          tel: res.data.data.tel,
          name: res.data.data.name,
          uid: res.data.data.uid,
        }
        updateUser(userObj);
      }
    })
  }

  useEffect(() => {
    const isToken = localStorage.getItem('token');
    console.log('isToken', isToken)
    if (isToken) {
      requestUserInfo();
    }
  }, []);

  return (
    <div className="App" >
      <UserContext.Provider value={{ user: user, requestLogin: requestLogin, requestLogout: requestLogout }}>
        <AlertContext.Provider value={{ alert: alertData, requestAlert: requestAlert }}>
          <Router>
            <Switch>
              <MainLayout exact path="/" component={DashBoard} />
              <LoginLayout exact path="/login" component={Login} />
              <MainLayout exact path="/store/management" component={StoreManagement} />
              <MainLayout exact path="/store/detail/:storeId?" component={StoreDetail} />
            </Switch>
          </Router>
          <Alert />
        </AlertContext.Provider>
      </UserContext.Provider>
    </div>
  );
}


export default App;