import AxiosInstance from "../axiosInstance";
import {
  ObjectToParams,
  ObjectToQueryString,
  ArrayToStringComma,
  statusStringToValue,
  arrayTOStringCommaByKey,
  dateFormatToStringType,
  stringToServiceType,
} from "../helper";
import { pageType } from "../../components/Paging";
import _ from "lodash";

const getList = (page: pageType) => {
  const queryObject = {
    count: page.itemsCountPerPage,
    page: page.activePage,
  };
  const qeuryString = ObjectToQueryString(queryObject);
  return AxiosInstance.get("list?" + qeuryString);
};

const getSearchedList = (
  key: string,
  value: string,
  page: pageType,
  rangeObject: any
) => {
  const queryObject = {
    count: page.itemsCountPerPage,
    page: page.activePage,
    [key]: value,
    startDate: dateFormatToStringType(rangeObject.start),
    endDate: dateFormatToStringType(rangeObject.end),
  };
  const qeuryString = ObjectToQueryString(queryObject);
  return AxiosInstance.get("list?" + qeuryString);
};

const register = (basicInfo: any) => {
  const data = {
    ...basicInfo,
    lat: parseFloat(basicInfo.lat),
    lng: parseFloat(basicInfo.lng),
    receiptDate: dateFormatToStringType(basicInfo.receiptDate),
  };
  const params = ObjectToParams(data);
  return AxiosInstance.post("pharm/register", params);
};

const getDetail = (storeId: string) => {
  const queryObject = {
    storeId: storeId,
  };
  const qeuryString = ObjectToQueryString(queryObject);

  return AxiosInstance.get("detail?" + qeuryString);
};

const modify = (modifyInfo: any) => {
  let paramsObj = _.clone(modifyInfo);
  if (modifyInfo.installDate) {
    paramsObj.installDate = dateFormatToStringType(modifyInfo.installDate);
  } else {
    paramsObj.installDate = "";
  }
  paramsObj.status = statusStringToValue(modifyInfo.status);
  paramsObj.serviceType = stringToServiceType(modifyInfo.serviceType);
  const params = ObjectToParams(paramsObj);
  return AxiosInstance.post("detail/modify", params);
};

const remove = (storeId: string) => {
  const data = {
    storeId: storeId,
  };
  const params = ObjectToParams(data);

  return AxiosInstance.post("/pharm/delete", params);
};

const Pharm = {
  getList: getList,
  getDetail: getDetail,
  getSearchedList: getSearchedList,
  register: register,
  modify: modify,
  remove: remove,
};
export default Pharm;
