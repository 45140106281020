import React, { useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { dataObjectType } from '../pages/DashBoard';
import 'chartjs-plugin-datalabels';

interface Props {
  onClick: (index: number) => void;
  data: dataObjectType;
  selectedIndex: number;
}

const CustomChart: React.SFC<Props> = (props) => {
  const chartRef = useRef(null);

  const data = {
    labels: ['설치접수', '설치예정', '설치보류'],
    datasets: [{
      data: [props.data.receiptList.length, props.data.scheduleList.length, props.data.holdList.length],
      backgroundColor: [
        props.selectedIndex == 0 ? 'rgba(244, 212, 37, 1)' : 'rgba(244, 212, 37, 0.2)',
        props.selectedIndex == 1 ? 'rgba(49, 50, 253, 1)' : 'rgba(49, 50, 253, 0.2)',
        props.selectedIndex == 2 ? 'rgba(229, 81, 81, 1)' : 'rgba(229, 81, 81, 0.2)',
      ],
      borderColor: [
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
        'rgba(255, 255, 255, 1)',
      ],
      hoverBackgroundColor: [
        'rgba(244, 212, 37, 0.7)',
        'rgba(49, 50, 253, 0.7)',
        'rgba(229, 81, 81, 0.7)',
      ],

      borderWidth: 5,
    },]
  }

  const options = {
    layout: {
      padding: 10,
    },
    legend: {
      labels: {
        fontSize: 16,

      },
      position: 'bottom',
    },
    tooltips: {
      bodyFontSize: 16,
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          let allData = data.datasets[tooltipItem.datasetIndex].data;
          let tooltipLabel = data.labels[tooltipItem.index];
          let tooltipData = allData[tooltipItem.index];
          let total = 0;
          for (let i in allData) {
            total += parseFloat(allData[i]);
          }
          let tooltipPercentage = Math.round((tooltipData / total) * 100);
          return tooltipLabel + ': ' + tooltipData + ' (' + tooltipPercentage + '%)';
        }
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        color: '#fff',
        font: {
          weight: 'bold',
          size: 20,
        },
        formatter: (value: any) => {
          if (value == 0) {
            return null;
          }
          let total = 0;
          for (let i in props.data) {
            total += props.data[i].length;
          }
          var tooltipPercentage = Math.round((value / total) * 100);
          return value + ' (' + tooltipPercentage + '%)';
        },
      }
    },
  }

  useEffect(() => {
    const chartCurrent: any = chartRef.current;
    if (chartCurrent) {
      const chartInstance = chartCurrent.chartInstance;
      const datasets = chartInstance.chart.$datalabels._datasets[0];
      const targetElement = datasets[props.selectedIndex];

      if (targetElement) {

        const targetModel = targetElement._el._model;
        targetModel.backgroundColor = 'blue';

      }

    }
  }, [props.selectedIndex])
  return (
    < article className="custom-chart" >
      <Doughnut
        data={data}
        ref={chartRef}
        height={600}
        options={options}
        onElementsClick={(elems) => {
          if (elems.length > 0) {
            props.onClick(elems[0]._index);
          }
        }}
      />
    </article >
  )
}

export default CustomChart;
