import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../../context/user';
import { useHistory } from "react-router-dom";
import API from '../../api';


interface LoginType {
  id: string;
  pw: string;
  isRetainAuth: boolean;
}
const initialLoginInfo: LoginType = {
  id: '',
  pw: '',
  isRetainAuth: false,
}

const LoginForm = () => {
  let history = useHistory();
  const { user, requestLogin } = useContext(UserContext);

  useEffect(() => {
    const isToken = localStorage.getItem('token');
    //init login handler
    if (isToken) {
      history.push('/')
    }
  }, [user]);


  const [loginInfo, updateBasicInfo] = useState(initialLoginInfo);

  const onChangeInput = (e: any) => {
    e.persist();
    const key = e.target.name;
    let value = e.target.value;

    if (key == 'isRetainAuth') {
      value = e.target.checked;
    }
    updateBasicInfo({
      ...loginInfo,
      [key]: value
    })
  }

  const onKeypress = (e: any) => {
    if (e.key == 'Enter') {
      requestLogin(loginInfo.id, loginInfo.pw, loginInfo.isRetainAuth);
    }
  }
  const onClickLogin = () => {
    console.log('loginInfo', loginInfo)
    requestLogin(loginInfo.id, loginInfo.pw, loginInfo.isRetainAuth);
  }

  const toggleIsRetain = () => {
    updateBasicInfo({
      ...loginInfo,
      isRetainAuth: !loginInfo.isRetainAuth

    })
  }

  return (
    <article className="login-form">
      <section className="form__box">
        <label>아이디</label>
        <input value={loginInfo.id} name="id" placeholder="아이디를 입력하세요" onChange={onChangeInput} />
      </section>
      <section className="form__box">
        <label>비밀번호</label>
        <input type="password" value={loginInfo.pw} name="pw" placeholder="비밀번호를 입력하세요" onKeyPress={onKeypress} onChange={onChangeInput} />
      </section>

      <section className="form__box option">
        <div>
          <input id="check-save-id" onChange={onChangeInput} name="isRetainAuth" type="checkbox" />
          <label className={loginInfo.isRetainAuth ? 'on' : 'off'} htmlFor="check-save-id"></label>
          <span onClick={toggleIsRetain}>로그인 상태 유지</span>
        </div>
      </section>

      <section className="form__box action">
        <button onClick={onClickLogin}>
          로그인
          </button>
      </section>
      <section className="form__box caption">
        <b>문의 전화  : 1600 - 3122</b>
      </section>
    </article>
  )
}

export default LoginForm;