import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context';
import { Table, CustomDropdown, Paging } from '../../components';
import { pageType } from '../../components/Paging';
import { useHistory, useParams, useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import API from '../../api';
import _ from 'lodash';
import { dateFormatToStringType } from '../../api/helper';

interface TableDataType {
  index: number;
  area: string;
  installDate: Date;
  isOn: boolean;
  license: string;
  pharmName: string;
  receiptDate: string;
  status: string;
  storeId: string;
  tabletList: Array<any>;
  isRunAgent: boolean;
}

interface StatusObjectType {
  [R: string]: string;
  S: string;
  H: string;
  F: string;
  C: string;
}

interface SearchObjectType {
  value: string;
  type: string;
}

interface rangeObjectType {
  start: Date;
  end: Date;
}

const STATUS_OBJECT: StatusObjectType = {
  'R': '접수',
  'S': '예정',
  'H': '보류',
  'F': '완료',
  'C': '취소'
};

const SearchDropdownOption = [
  '약국명',
  '면허번호',
  '지역',
  '설치상태',
];

const StatusDropdwonOption = [
  '접수',
  '예정',
  '보류',
  '완료',
  '취소',
]

const PageDropdownOption = [
  '10',
  '20',
  '30',
  '50',
];

const SearchTypeTextToKey = (text: string) => {
  switch (text) {
    case '약국명': {
      return 'name';
    }
    case '면허번호': {
      return 'licenseNo';
    }
    case '지역': {
      return 'area';
    }
    case '설치상태': {
      return 'status';
    }
    default: {
      return '';
    }
  }
}

const DateStringToYYYYMMDD = (dateSting: string) => {
  if (dateSting) {
    return dateSting.slice(0, 10);
  } else {
    return '';
  }
}

const ParseDate = (str: string) => {
  var y = str.substr(0, 4);
  var m = str.substr(5, 2);
  var d = str.substr(8, 2);
  return new Date(Number(y), Number(m) - 1, Number(d));
}

export const StoreManagement = () => {
  let history = useHistory();
  let location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);

  const initialPage: pageType = {
    activePage: 1,
    itemsCountPerPage: 10,
    totalItemsCount: 100,
    pageRangeDisplayed: 10
  }

  const initialTableData: Array<TableDataType> = [
    {
      index: 0,
      area: "",
      installDate: new Date(),
      isOn: false,
      license: "",
      pharmName: "",
      receiptDate: "",
      status: "R",
      storeId: "",
      tabletList: [],
      isRunAgent: false,
    }
  ];

  const initialSearchObject: SearchObjectType = {
    value: "",
    type: SearchDropdownOption[0],
  }

  const initialStatusDropdwonObject: SearchObjectType = {
    type: StatusDropdwonOption[0],
    value: 'R',
  }

  const initialrangeObject: rangeObjectType = {
    start: new Date(),
    end: new Date(),
  }
  const { user } = useContext(UserContext);
  const [tableData, updateTableData] = useState(initialTableData);
  const [page, updatePage] = useState(initialPage);
  const [searchObject, updateSearchObject] = useState(initialSearchObject);
  const [statusDropdwon, updateStatusDropdown] = useState(initialStatusDropdwonObject);
  const [rangeObject, updateRangeObject] = useState(initialrangeObject)

  useEffect(() => {
    //back button history issue -> useEffect by location and then get search params
    console.log('StoreManagement location Change', location)
    let newPage = {
      activePage: Number(urlParams.get('activePage')) || 1,
      itemsCountPerPage: Number(urlParams.get('itemsCountPerPage')) || 10,
      totalItemsCount: 100,
      pageRangeDisplayed: 10
    }
    const newSearchObject = {
      value: urlParams.get('value') || "",
      type: urlParams.get('type') || SearchDropdownOption[0],
    }

    const initialStartDate = new Date(2019, 9, 1);
    // lastMonth.setDate(lastMonth.getDate() - 100);

    const newRangeObject = {
      start: urlParams.has('start') ? ParseDate(urlParams.get('start') || '2000-00-00') : initialStartDate,
      end: urlParams.has('end') ? ParseDate(urlParams.get('end') || '2000-00-00') : new Date(),
    }
    console.log('newRangeObject', newRangeObject)

    const searchKey = SearchTypeTextToKey(newSearchObject.type);
    API.Pharm.getSearchedList(searchKey, newSearchObject.value, newPage, newRangeObject).then(res => {
      const data = res.data.data;
      console.log('getSearchedList', data)
      updatePage({
        ...newPage,
        totalItemsCount: data.totalCount
      })
      updateSearchObject(newSearchObject);
      updateTableData(res.data.data.pharmList);
      updateRangeObject(newRangeObject);
    })

  }, [location])

  const requestSearchByAction = () => {
    //search button action일 경우, 해당 서치에 사용된 history params 저장과 동시에 initpage =1
    const searchKey = SearchTypeTextToKey(searchObject.type);
    const searchValue = searchObject.type === '설치상태' ? statusDropdwon.value : searchObject.value;
    const initPage = _.clone(page);
    initPage.activePage = 1;

    const start = dateFormatToStringType(rangeObject.start);
    const end = dateFormatToStringType(rangeObject.end);
    console.log('start', start)
    console.log('end', end)


    API.Pharm.getSearchedList(searchKey, searchValue, initPage, rangeObject).then(res => {
      const data = res.data.data;
      console.log('getSearchedList', data)
      updatePage({
        ...initPage,
        totalItemsCount: data.totalCount
      })
      urlParams.set('activePage', String(1));
      urlParams.set('type', String(searchObject.type));
      urlParams.set('value', String(searchValue));
      urlParams.set('start', String(start));
      urlParams.set('end', String(end));
      history.push(`/store/management?${urlParams}`);
      updateTableData(res.data.data.pharmList);
    })
  }

  const updateActivePage = (pageValue: number) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('activePage', String(pageValue));
    history.push(`/store/management?${urlParams}`);
  }

  const onChangeDropdownPage = (pageObj: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('itemsCountPerPage', String(pageObj.value));
    urlParams.set('activePage', '1');
    history.push(`/store/management?${urlParams}`);
  }

  const onChangeDropdownSearch = (dropdownSearch: any) => {
    console.log('onChangeDropdownSearch', dropdownSearch)
    updateSearchObject({
      ...searchObject,
      type: dropdownSearch.value
    })
  }

  const onChangeDropdwonStatus = (dropdownSearch: any) => {
    let value = '';
    for (let i in STATUS_OBJECT) {
      console.log('StatusDropdwonOption', i)
      console.log('StatusDropdwonOption', STATUS_OBJECT[i])
      if (STATUS_OBJECT[i] === dropdownSearch.value) {
        value = i;
      }
    }
    updateStatusDropdown({
      type: dropdownSearch.value,
      value: value,
    })
  }

  const onChangeSearchInput = (e: any) => {
    const value = e.target.value;
    updateSearchObject({
      ...searchObject,
      value: value
    })
  }

  const hanleRangeChange = (value: any, key: string) => {
    console.log('hanleRangeChange value', value)
    console.log('hanleRangeChange key', key)
    if (key == 'start') {
      updateRangeObject({
        ...rangeObject,
        start: value,
      })
    } else {
      updateRangeObject({
        ...rangeObject,
        end: value,
      })
    }
  }

  const onClickRow = (row: any) => {
    console.log('onClickRow', row)
    const selectedIndex = row.index;
    const targetStoreId = tableData[selectedIndex].storeId;
    history.push(`/store/detail/${targetStoreId}`);
  }

  const TableColumns = [
    {
      Header: '순번',
      accessor: 'index',
    },
    {
      Header: '접수 일자',
      accessor: 'receiptDate',
      Cell: (props: any) => {
        return (
          <span>{DateStringToYYYYMMDD(props.cell.value)}</span>
        );
      },
    },
    {
      Header: '약국명',
      accessor: 'pharmName', // accessor is the "key" in the data
    },
    {
      Header: '면허번호',
      accessor: 'license',
    },
    {
      Header: '지역',
      accessor: 'area',
    },
    {
      Header: '상태',
      accessor: 'status',
      Cell: (props: any) => {
        return (
          <span className={'status-text ' + props.cell.value}>{STATUS_OBJECT[props.cell.value]}</span>
        );
      },
    },
    {
      Header: '태블릿 상태',
      accessor: 'tabletList',
      Cell: (props: any) => {
        const targetTabletList = props.cell.value;
        let onList = [];
        let offList = [];
        let rowIndex = props.cell.row.index;
        let isRunAgent = tableData[rowIndex].isRunAgent;
        console.log('index', props.cell.row.index)
        for (let i in targetTabletList) {
          if (targetTabletList[i].isOn) {
            onList.push(targetTabletList[i])
          } else {
            offList.push(targetTabletList[i])
          }
        }

        if (targetTabletList.length == 0) {
          return (
            <ul className="tabelt__status">
              <li>< div className="status empty" /></li>
            </ul>
          )
        }

        return (
          <ul className="tabelt__status">
            {targetTabletList.map((item: any, index: number) => {
              console.log('item', item)
              return (
                <li key={item.serialNo + '_' + index} className={isRunAgent ? "table__item run" : 'table__item'} >
                  {item.isOn ? (<div className="device-on">on</div>) : <div className="device-off">off</div>}
                  {item.isConnected ? (<div className="connected-on">conn</div>) : <div className="connected-off">conn</div>}
                </li>
              )
            })}
          </ul>
        );
      },
    },
  ];

  return (
    <article className="store-management-page">
      <section className="page-header">
        <h1>약국관리</h1>
      </section>

      <section className="table-title">
        <header>
          전체 <b>{page.totalItemsCount}</b>개의 약국
          </header>
        <div className="option__box">
          <CustomDropdown
            options={PageDropdownOption}
            value={String(page.itemsCountPerPage)}
            onChange={onChangeDropdownPage} />
        </div>
      </section>

      <section className="search__box">
        <section className="range__box">
          <DatePicker
            placeholderText="시작일자"
            dateFormat="yyyy-MM-dd"
            selected={rangeObject.start}
            onChange={(value) => hanleRangeChange(value, 'start')}
          />
          <b>
            ~
          </b>
          <DatePicker
            placeholderText="종료일자"
            dateFormat="yyyy-MM-dd"
            selected={rangeObject.end}
            onChange={(value) => hanleRangeChange(value, 'end')}
          />
        </section>

        <section className="input__box">
          <CustomDropdown
            options={SearchDropdownOption}
            value={searchObject.type}
            onChange={onChangeDropdownSearch} />
          {searchObject.type === '설치상태' ? (
            <CustomDropdown
              className="status"
              options={StatusDropdwonOption}
              value={statusDropdwon.type}
              onChange={onChangeDropdwonStatus} />
          ) : (
              <input
                value={searchObject.value}
                placeholder="검색어를 입력해주세요."
                onChange={onChangeSearchInput} />
            )}
        </section>

        <section className="button__box">
          <button onClick={requestSearchByAction}>검색</button>
        </section>
        {searchObject.type == '면허번호' &&
          <section className="guide__box">
            <span>
              면허번호는 5자리를 입력해주세요.
          </span>
          </section>
        }
      </section>

      <section className="table__box">
        <Table
          onClickRow={onClickRow}
          data={tableData}
          columns={TableColumns} />
      </section>
      <section className="paging__box">
        <Paging
          page={page}
          updateActivePage={updateActivePage} />
      </section>

      {/* {searchObject.type === '면허번호' &&
        <div className="guide__box">
          <span>면허번호 검색은 5자리를 입력해주세요.</span>
        </div>
      } */}

    </article>

  );
}
