import React from 'react';
import Pagination from "react-js-pagination";

export interface pageType {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  pageRangeDisplayed: number;
}

interface Props {
  page: pageType;
  updateActivePage: (page: number) => void;
}

const Paging: React.SFC<Props> = (props) => {
  const handlePageChange = (page: number) => {
    props.updateActivePage(page);
  }

  const { page } = props;
  return (
    <article className="custom-paging">
      <Pagination
        activePage={page.activePage}
        itemsCountPerPage={page.itemsCountPerPage}
        totalItemsCount={page.totalItemsCount}
        pageRangeDisplayed={page.pageRangeDisplayed}
        onChange={handlePageChange}
      />
    </article>
  )
}

export default Paging;
