import axios from "axios";
import { BASE_URL } from "./config";

const AxiosInstance = axios.create({
  baseURL: BASE_URL,
  responseType: "json",
});

// 요청 인터셉터 추가
AxiosInstance.interceptors.request.use(
  (config) => {
    //setup JWT token
    console.log("config", config);
    const tokenString = localStorage.getItem("token");
    let token = null;
    if (tokenString) {
      token = JSON.parse(tokenString).token;
    }
    const toeknHeader = {
      Authorization: `Bearer ${token}`,
    };
    config.headers = toeknHeader;

    // IE config
    if (config.method === "get") {
      const customHeaders = {
        "Cache-Control": "no-cache, no-store, must-revalidate",
        Pragma: "no-cache",
        Expires: 0,
        ...toeknHeader,
      };
      config.headers = customHeaders;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 응답 인터셉터 추가
AxiosInstance.interceptors.response.use(
  (response) => {
    console.log("response", response);

    return response;
  },
  (error) => {
    //login session expired
    if (error.response && 401 === error.response.status) {
      console.log("work 401", window.location.pathname);
      localStorage.setItem("token", "");

      if (window.location.pathname !== "/login") {
        window.location.href = "/login";
        return;
      }
    }
    if (error) {
      console.log("net work error", error);
    }

    return Promise.reject(error);
  }
);

export default AxiosInstance;
