import AxiosInstance from "../axiosInstance";
import {
  ObjectToParams,
  ObjectToQueryString,
  ArrayToStringComma,
} from "../helper";

const requestLogin = (id: string, pw: string, isRetainAuth: boolean) => {
  const queryObject = {
    id: id,
    password: pw,
    isRetainAuth: isRetainAuth,
  };
  const qeuryString = ObjectToQueryString(queryObject);
  return AxiosInstance.get("login?" + qeuryString);
};

const getUserInfo = () => {
  return AxiosInstance.get("user");
};

const User = {
  requestLogin: requestLogin,
  getUserInfo: getUserInfo,
};
export default User;
