import React, { useContext, useEffect, useState } from 'react';
import { AlertContext } from '../context';

interface Props { }

const typeValueToString = (type: string) => {
  switch (type) {
    case 'error': {
      return '에러';
    };
    case 'warning': {
      return '경고';
    }
    case 'confirm': {
      return '확인';
    }
    default: {
      return 'ALERT';
    }
  }
}

const initialFlag: any = {
  isOn: false,
}
let timer: any = null;

const Alert: React.SFC<Props> = (props) => {
  const { alert } = useContext(AlertContext);
  const [flag, updateFlag] = useState(initialFlag);
  useEffect(() => {
    console.log('alert')
    updateFlag({ isOn: false })

    if (timer) {
      clearTimeout(timer);
      setTimeout(() => {
        if (alert.type !== "init") {
          updateFlag({ isOn: true })
        }
      }, 500);
    }
    timer = setTimeout(() => {
      console.log('work')
      updateFlag({ isOn: false })
    }, 5000)


  }, [alert])

  const close = () => {
    updateFlag({ isOn: false })
  }
  return (
    <article className={flag.isOn ? 'custom-alert on' : 'custom-alert off'}>
      <div className="alert__box">
        <header className={alert.type}>{typeValueToString(alert.type)} <div className="close__btn" onClick={close}>close</div></header>
        <span className="message">
          {alert.message}
        </span>
      </div>
    </article>
  )
}

export default Alert;
