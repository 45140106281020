import React from "react";

export interface AlertContextType {
  alert: AlertType;
  requestAlert: (type: string, message: string) => void;
}

export interface AlertType {
  type: string;
  message: string;
}

export const alert: AlertType = {
  type: "error",
  message: "test message",
};

export const AlertContext = React.createContext<AlertContextType>({
  alert: alert,
  requestAlert: (type: string, message: string) => {},
});
